import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, of, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { selectAccount, selectAuthState } from '@app/shared/store/user/user.reducer';
import { AccountRead } from '@app/shared/openapi';
import { setSystemLoader } from '@app/shared/store/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      take(1),
      switchMap(({ access }) => {
        if (!access) {
          void this.router.navigate(['/login']);
          return of(false);
        }

        return this.store.select(selectAccount).pipe(
          filter((account) => !!account),
          map((account: AccountRead): boolean => {
            if (!account?.company?.isSubscriptionInitialized) {
              void this.router.navigateByUrl('/signup?user=true');
              this.store.dispatch(setSystemLoader({ data: true }));
              return false;
            }

            return true;
          }),
        );
      }),
    );
  }
}
