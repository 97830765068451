import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectAuthState } from '@app/shared/store/user/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthRegistrationGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      take(1),
      switchMap(({ access }) => {
        if (!access) {
          void this.router.navigate(['/login']);
          return of(false);
        }

        return of(true);
      }),
    );
  }
}
