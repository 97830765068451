/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VPN { 
    company?: string | null;
    readonly createdAt?: string;
    readonly id?: string;
    ipAddress: string;
    networkStatus?: VPN.NetworkStatusEnum;
    provisionStatus?: VPN.ProvisionStatusEnum;
    type: VPN.TypeEnum;
    readonly updatedAt?: string;
    vpnNode?: string | null;
}
export namespace VPN {
    export type NetworkStatusEnum = 'ONLINE' | 'OFFLINE';
    export const NetworkStatusEnum = {
        Online: 'ONLINE' as NetworkStatusEnum,
        Offline: 'OFFLINE' as NetworkStatusEnum
    };
    export type ProvisionStatusEnum = 'ACTIVE' | 'BLOCKED';
    export const ProvisionStatusEnum = {
        Active: 'ACTIVE' as ProvisionStatusEnum,
        Blocked: 'BLOCKED' as ProvisionStatusEnum
    };
    export type TypeEnum = 'VPNRouter' | 'VPNDevice';
    export const TypeEnum = {
        VpnRouter: 'VPNRouter' as TypeEnum,
        VpnDevice: 'VPNDevice' as TypeEnum
    };
}


