/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyRead } from './companyRead';
import { Provider } from './provider';


export interface SimCardRead { 
    apn?: string | null;
    comment?: string | null;
    company?: CompanyRead;
    countryIso2?: string | null;
    countryName?: string | null;
    readonly createdAt?: string;
    dataEnabled?: boolean;
    dataLimitEnabled?: boolean;
    readonly dataUsage?: string;
    deliveredAt?: string | null;
    deliveryStatus: SimCardRead.DeliveryStatusEnum;
    eid: string;
    externalId: string;
    iccid: string;
    readonly id?: string;
    imei?: string | null;
    imsi: string;
    ipAddress?: string;
    lastDataSession?: string | null;
    lastRegistrationDate?: string | null;
    lastReset?: string | null;
    lastSmsMobileOriginated?: string | null;
    lastSmsMobileTerminated?: string | null;
    lastSync?: string | null;
    msisdn?: string | null;
    name: string;
    readonly networkEvent?: string;
    networkStatus: SimCardRead.NetworkStatusEnum;
    operatorName?: string | null;
    primaryDns: string;
    printedIdentifierCode?: string | null;
    provider?: Provider;
    provisionStatus?: SimCardRead.ProvisionStatusEnum;
    ratType?: string | null;
    secondaryDns: string;
    securityEnabled?: boolean;
    smsEnabled?: boolean;
    readonly updatedAt?: string;
    vpnNode?: string | null;
}
export namespace SimCardRead {
    export type DeliveryStatusEnum = 'In Stock' | 'Is Delivered' | 'Lost';
    export const DeliveryStatusEnum = {
        InStock: 'In Stock' as DeliveryStatusEnum,
        IsDelivered: 'Is Delivered' as DeliveryStatusEnum,
        Lost: 'Lost' as DeliveryStatusEnum
    };
    export type NetworkStatusEnum = 'Online' | 'Offline' | 'Registered';
    export const NetworkStatusEnum = {
        Online: 'Online' as NetworkStatusEnum,
        Offline: 'Offline' as NetworkStatusEnum,
        Registered: 'Registered' as NetworkStatusEnum
    };
    export type ProvisionStatusEnum = 'Active' | 'Suspended';
    export const ProvisionStatusEnum = {
        Active: 'Active' as ProvisionStatusEnum,
        Suspended: 'Suspended' as ProvisionStatusEnum
    };
}


