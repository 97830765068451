/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Project } from './project';
import { ListScanPlanType } from './listScanPlanType';
import { ListScanPlanTagsInner } from './listScanPlanTagsInner';
import { ProjectDevice } from './projectDevice';
import { ListScanPlanResult } from './listScanPlanResult';
import { ScanPlanBTest } from './scanPlanBTest';
import { TrafficSegmentWithCurrentRetrieve } from './trafficSegmentWithCurrentRetrieve';


export interface ListScanPlan { 
    bTests: Array<ScanPlanBTest>;
    readonly devices?: Array<ProjectDevice>;
    duration?: string | null;
    finishedAt?: string | null;
    readonly id?: string;
    isDownloadAvailable?: boolean;
    project: Project;
    result?: ListScanPlanResult;
    readonly tags?: Array<ListScanPlanTagsInner>;
    readonly trafficSegments?: Array<TrafficSegmentWithCurrentRetrieve>;
    type?: ListScanPlanType;
}

