import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, withLatestFrom } from 'rxjs';
import { catchError, concatMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as AuthActions from './user.actions';
import { loginRedirect, setLoader } from './user.actions';
import { AuthService as AuthApiService } from '../../../services/api/core/auth.service';
import { Router } from '@angular/router';
import { AccountRead, AccountTokenObtainPairResponse, AuthService, UserService } from '../../openapi';
import { extractUserId } from '@app/shared/store/user/utils/helpers';
import * as UserActions from '@app/shared/store/user/user.actions';
import { selectAccount } from '@app/shared/store/user/user.reducer';
import { Toast } from '@app/components/utils/toast/toast';
import { RolesKeywords } from '@app/shared/store/user/utils/interfaces';

@Injectable()
export class AuthEffects {
  signIn$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signIn),
      concatMap((action) => {
        return this.authService.authTokenCreate(action.data).pipe(
          switchMap((data: AccountTokenObtainPairResponse) => {
            this.authApiService.setTokensToStorage(data);

            return [AuthActions.signInSuccess({ data, redirection: true })];
          }),
          takeUntil(this.actions$.pipe(ofType(AuthActions.signIn))),
          catchError((error) => {
            this.toast.error('Login failed. Please check your credentials and try again!');
            return of(AuthActions.signInFailure({ error: error as Error }));
          }),
        );
      }),
    );
  });

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInSuccess),
      concatMap((action) => {
        const userId = extractUserId(action.data?.['access']);

        return this.userService.userAccountRead(userId).pipe(
          switchMap((data: AccountRead) => {
            const actions: Action[] = [
              AuthActions.getSelfUserSuccess({
                data,
              }),
            ];

            if (action.redirection) {
              actions.push(
                loginRedirect({
                  role: data.role.keyword as RolesKeywords,
                  data,
                }),
              );
            } else {
              actions.push(setLoader({ data: false }));
            }

            return actions;
          }),
          takeUntil(this.actions$.pipe(ofType(AuthActions.getSelfUser))),
          catchError((error) => of(AuthActions.getSelfUserSuccessFailure({ error: error as Error }))),
        );
      }),
    ),
  );

  refreshUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshUser),
      withLatestFrom(this.store.select(selectAccount)),
      concatMap(([, account]) => {
        return this.userService.userAccountRead(account?.id).pipe(
          switchMap((data: AccountRead) => {
            return [AuthActions.getSelfUserSuccess({ data })];
          }),
          takeUntil(this.actions$.pipe(ofType(AuthActions.refreshUser))),
          catchError((error) => of(AuthActions.getSelfUserSuccessFailure({ error: error as Error }))),
        );
      }),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap((action) => {
        const serviceAction = action.logoutAll ? this.authService.authTokenLogoutAllCreate() : this.authService.authTokenLogoutCreate();

        return serviceAction.pipe(
          switchMap(() => {
            this.authApiService.logout();

            return [UserActions.clearSession()];
          }),
          takeUntil(this.actions$.pipe(ofType(AuthActions.logout))),
        );
      }),
    ),
  );

  clearSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.clearSession),
        tap(() => {
          void this.authApiService.deleteAuthTokensFromStorage();
          void this.router.navigateByUrl('/login');
          this.store.dispatch(UserActions.setLoader({ data: false }));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authApiService: AuthApiService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly toast: Toast,
  ) {}
}
