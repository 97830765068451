/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvoiceAmountSummary } from './invoiceAmountSummary';
import { CustomerBillingDetails } from './customerBillingDetails';
import { InvoicePaymentIntent } from './invoicePaymentIntent';


export interface GetInvoice { 
    amountSummary?: InvoiceAmountSummary;
    readonly billingReason?: string;
    currency?: string | null;
    customerDetails?: CustomerBillingDetails;
    downloadUrl?: string | null;
    finalizedAt?: string | null;
    readonly id?: string;
    itemName?: string | null;
    paidAt?: string | null;
    paymentIntent?: InvoicePaymentIntent;
    receivedTokens?: string | null;
    readonly recurrence?: string;
    status: GetInvoice.StatusEnum;
    stripeCreatedAt: string;
    readonly stripeId?: string;
    readonly totalSum?: string;
}
export namespace GetInvoice {
    export type StatusEnum = 'DRAFT' | 'OPEN' | 'PAID' | 'UNCOLLECTIBLE' | 'VOID';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Open: 'OPEN' as StatusEnum,
        Paid: 'PAID' as StatusEnum,
        Uncollectible: 'UNCOLLECTIBLE' as StatusEnum,
        Void: 'VOID' as StatusEnum
    };
}


