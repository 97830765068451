/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvoiceCharge } from './invoiceCharge';


export interface InvoicePaymentIntent { 
    readonly canceledAt?: string;
    cancellationReason: string;
    latestCharge?: InvoiceCharge;
    status: string;
    stripeId: string;
}

